<template>
  <GmapMarker
    :position="place.position"
    :clickable="true"
    :draggable="false"
    :title="place.title"
    :label="{
      text: 'S',
      color: '#303030',
      fontFamily: 'sans-serif',
      fontWeight: 'bold',
      fontSize: '16px',
    }"
    @click="openWindow"
  >
    <GmapInfoWindow
      v-if="selectedEv && startBatteryLevel && startAvailableRange"
      :opened="opened"
      @closeclick="opened = false"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <div class="center">
              <v-icon color="#101010" small>mdi-battery-charging-100</v-icon>
              {{ startBatteryLevel.toFixed() }} %
            </div>
            <div class="center">
              <v-icon color="#101010" small>mdi-car</v-icon>
              {{ startAvailableRange.toFixed(1) }} km
            </div>
          </div>
        </template>
        <div>
          <div>スタート地点での予想充電池残量</div>
          <div>スタート地点での予想走行可能距離</div>
        </div>
      </v-tooltip>
    </GmapInfoWindow>
  </GmapMarker>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      opened: true,
    };
  },
  props: ["place"],
  methods: {
    openWindow() {
      this.opened = true;
    },
  },
  computed: mapState([
    "startBatteryLevel",
    "startAvailableRange",
    "selectedEv",
  ]),
};
</script>

<style scoped>
.center {
  text-align: center;
  border-bottom: 1px solid #101010;
  margin: 5px;
}
</style>
