<template>
  <v-snackbar v-model="snackbar" :timeout="10000" :color="color" top>
    <div>{{ text }}</div>
    <v-card-actions
      ><v-spacer></v-spacer>
      <v-btn @click="snackbar = false" text>Close</v-btn></v-card-actions
    >
  </v-snackbar>
</template>

<script>
import { EventBus } from "@/event.js";

export default {
  data() {
    return {
      snackbar: false,
      text: null,
      color: null,
    };
  },
  mounted() {
    EventBus.$on("error", (message) => {
      this.$gtag.event("error", {
        event_category: "errorShown",
        event_label: message,
      });
      this.text = message;
      this.snackbar = true;
      this.color = "error";
    });
    EventBus.$on("success", (message) => {
      this.$gtag.event("success", {
        event_category: "successShown",
        event_label: message,
      });
      this.text = message;
      this.snackbar = true;
      this.color = "success";
    });
  },
};
</script>

