<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <v-card>
      <v-card-title>位置情報の許可</v-card-title>
      <v-card-text>
        位置情報の取得を許可していただくと、現在地の周囲の充電器の検索を簡単に行うことができます。
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="secondary" @click="permissionDenied">拒否</v-btn>
        <v-btn color="secondary" dark @click="permissionAccepted"
          >許可する</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "@/event.js";

export default {
  data() {
    return {
      dialog: false,
      callback: null,
    };
  },
  methods: {
    permissionAccepted() {
      this.$gtag.event("click", {
        event_category: "permission",
        event_label: 'accepted',
      });
      this.dialog = false;
      this.callback();
      this.$store.commit("geoPermission", true);
    },
    permissionDenied() {
      this.$gtag.event("click", {
        event_category: "permission",
        event_label: 'denied',
      });
      this.dialog = false;
    }
  },
  mounted() {
    EventBus.$on("geoPermission", (callback) => {
      this.dialog = true;
      this.callback = callback;
    });
  },
};
</script>

