import { render, staticRenderFns } from "./LegendSheet.vue?vue&type=template&id=215fb2c6&scoped=true&"
import script from "./LegendSheet.vue?vue&type=script&lang=js&"
export * from "./LegendSheet.vue?vue&type=script&lang=js&"
import style0 from "./LegendSheet.vue?vue&type=style&index=0&id=215fb2c6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "215fb2c6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBottomSheet,VDivider,VImg,VListItem,VListItemAvatar,VListItemContent,VSheet})
