<template>
  <GmapInfoWindow :opened="opened" :options="options">
    <div :id="`${chargerInfo.id}-window`" class="ma-1 inner-div">
      <v-container fluid>
        <v-row>
          <v-avatar>
            <v-img
              contain
              height="35px"
              v-if="
                chargerInfo.rapidChargerFlag && chargerInfo.normalChargerFlag
              "
              src="/img/charger_icon/charge_icon_set_01.png"
            ></v-img>
            <v-img
              contain
              height="35px"
              v-else-if="chargerInfo.rapidChargerFlag"
              src="/img/charger_icon/charge_icon_fast_01.png"
            ></v-img>
            <v-img
              contain
              height="35px"
              v-else
              src="/img/charger_icon/charge_icon_normal_01.png"
            ></v-img>
          </v-avatar>
        </v-row>
        <v-row class="my-1">
          <v-col cols="10">{{ chargerInfo.name }}</v-col>
          <v-col cols="2" class="pa-0">
            <v-btn icon @click="switchFav">
              <v-icon v-if="faved" color="red">mdi-heart</v-icon>
              <v-icon v-else color="gray">mdi-heart-plus-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div class="caption" v-if="start">
          <span>始点から</span>
          <span
            >{{
              (
                hubeny(
                  start.position.lat,
                  start.position.lng,
                  chargerInfo.location.lat,
                  chargerInfo.location.lon
                ) / 1000
              ).toFixed(1)
            }}km</span
          >
        </div>
        <div class="caption" v-if="start && destination">
          <span v-if="start && destination"
            >終点から{{
              (
                hubeny(
                  destination.position.lat,
                  destination.position.lng,
                  chargerInfo.location.lat,
                  chargerInfo.location.lon
                ) / 1000
              ).toFixed(1)
            }}km</span
          >
        </div>
        <div v-if="chargerInfo.numberOfRapidCharger">
          <div class="caption font-weight-bold">急速充電器</div>
          <div class="body-2 ml-3">
            {{ chargerInfo.numberOfRapidCharger }}台
          </div>
        </div>
        <div v-if="chargerInfo.numberOfRapidCharger">
          <div class="caption font-weight-bold">急速充電器営業時間</div>
          <div class="body-2 ml-3">
            {{
              chargerInfo.RapidChargerBusinessTime
                ? chargerInfo.RapidChargerBusinessTime
                : "不明"
            }}
          </div>
        </div>
        <div
          v-if="
            chargerInfo.numberOf200vCharger || chargerInfo.numberOf100vCharger
          "
        >
          <div class="caption font-weight-bold">普通充電器</div>
          <div class="body-2 ml-3">
            {{
              chargerInfo.numberOf100vCharger + chargerInfo.numberOf200vCharger
            }}台
          </div>
        </div>
        <div
          v-if="
            chargerInfo.numberOf200vCharger || chargerInfo.numberOf100vCharger
          "
        >
          <div class="caption font-weight-bold">普通充電器営業時間</div>
          <div class="body-2 ml-3">
            {{
              chargerInfo.NormalChargerBusinessTime
                ? chargerInfo.NormalChargerBusinessTime
                : "不明"
            }}
          </div>
        </div>
        <div>
          <div class="caption font-weight-bold">休業日</div>
          <div class="body-2 ml-3">
            {{
              chargerInfo.NormalChargerHoliday
                ? chargerInfo.NormalChargerHoliday
                : chargerInfo.RapidChargerHoliday
                ? chargerInfo.RapidChargerHoliday
                : "不明"
            }}
          </div>
        </div>
        <div class="caption">
          <ExtarnalLink
            :url="`https://www.google.com/maps/search/?api=1&query=${chargerInfo.location.lat},${chargerInfo.location.lon}`"
            linkText="Googleマップで開く"
            :linkEventLabel="`${this.chargerInfo.id} in google map`"
          />
        </div>
        <div v-if="isIOS" class="caption">
          <ExtarnalLink
            :url="`https://maps.apple.com/?q=${chargerInfo.location.lat},${chargerInfo.location.lon}`"
            linkText="マップアプリで開く"
            :linkEventLabel="`${this.chargerInfo.id} in apple map`"
          />
        </div>
      </v-container>
    </div>
  </GmapInfoWindow>
</template>

<script>
import { mapState } from "vuex";
import { hubeny } from "@/lib/hubeny.js";
import { EventBus } from "@/event.js";
import ExtarnalLink from "@/components/ExtarnalLink.vue";

export default {
  components: { ExtarnalLink },
  data() {
    return {
      isIOS: false,
      options: {
        minWidth: "220px",
      },
    };
  },
  props: ["opened", "chargerInfo"],
  computed: {
    ...mapState(["start", "destination", "favedChargers"]),
    faved: function () {
      if (this.chargerInfo) {
        return this.chargerInfo.id in this.favedChargers;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.isIOS = /iP(hone|(o|a)d)/.test(navigator.userAgent);
  },
  methods: {
    hubeny(lat1, lng1, lat2, lng2) {
      return hubeny(lat1, lng1, lat2, lng2);
    },
    switchFav() {
      if (this.faved) {
        this.$gtag.event("faved", {
          event_category: "addFavorite",
          event_label: String(this.chargerInfo.id),
        });
        this.$store.commit("DeleteFavedCharger", this.chargerInfo);
      } else if (Object.keys(this.favedChargers).length >= 10) {
        this.$gtag.event("faved", {
          event_category: "denied",
        });
        EventBus.$emit("error", "お気に入り登録できるのは10か所までです");
      } else {
        this.$gtag.event("faved", {
          event_category: "removeFavorite",
          event_label: String(this.chargerInfo.id),
        });
        this.$store.commit("AddFavedCharger", this.chargerInfo);
      }
    },
  },
};
</script>

<style>
.inner-div {
  width: 250px;
}
</style>