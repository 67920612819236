<template>
  <span>
    <GmapMarker
      :position="{
        lat: chargerInfo.location.lat,
        lng: chargerInfo.location.lon,
      }"
      :animation="animation"
      :icon="setIcon(chargerInfo)"
      :clickable="true"
      :title="chargerInfo.name"
      :draggable="false"
      cursor="pointer"
      @click="markerClick"
      @mouseover="mouseover"
      @mouseout="mouseout"
    >
      <ChargerWindow :opened="opened" :chargerInfo="chargerInfo">
      </ChargerWindow>
    </GmapMarker>
  </span>
</template>

<script>
import { EventBus } from "@/event.js";
import { mapState } from "vuex";
import ChargerWindow from "@/components/ChargerWindow.vue";

export default {
  data() {
    return {
      dialog: false,
      opened: false,
      animation: 0,
      blocked: false,
    };
  },
  props: ["chargerInfo", "index"],
  components: { ChargerWindow },
  methods: {
    setIcon(chargerInfo) {
      let iconInfo = {
        scaledSize: { width: 40, height: 43, f: "%", b: "%" },
      };
      if (chargerInfo.rapidChargerFlag && chargerInfo.normalChargerFlag) {
        iconInfo.url = "/img/charger_icon/charge_icon_set_01.png";
      } else if (chargerInfo.rapidChargerFlag) {
        iconInfo.url = "/img/charger_icon/charge_icon_fast_01.png";
      } else {
        iconInfo.url = "/img/charger_icon/charge_icon_normal_01.png";
      }
      return iconInfo;
    },
    markerClick() {
      EventBus.$emit("chargerMarkerClicked", this.chargerInfo.id);
      this.$gtag.event("click", {
        event_category: "clickCharger",
        event_label: String(this.chargerInfo.id),
      });
      this.opened = !this.opened;
    },
    mouseover() {
      if (!this.popBlocked) {
        this.$store.commit("popBlocked", true);
        this.animation = 4;
        EventBus.$emit("chargerMarkerClicked", this.chargerInfo.id);
        this.opened = true;
        const that = this;
        setTimeout(() => {
          that.$store.commit("popBlocked", false);
        }, 200);
      }
    },
    mouseout() {
      this.animation = 0;
    },
  },
  mounted() {
    EventBus.$on("chargerMarkerClicked", (id) => {
      if (this.chargerInfo.id != id) {
        this.opened = false;
      }
    });
    // Favlistクリックされると"clickFavorite"イベントが来る
    EventBus.$on("clickFavorite", (id) => {
      if (this.chargerInfo.id == id) {
        this.animation = 1;
      } else {
        this.animation = 0;
      }
    });
  },
  computed: mapState(["popBlocked"]),
};
</script>

<style>
.gm-style-iw {
  -webkit-animation: fadeIn 500ms; /* Chrome, Safari, Opera */
  animation: fadeIn 500ms;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Standard syntax */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>