<template>
  <span>
    <GmapMarker
      v-if="currentPosition"
      :position="currentPosition"
      :clickable="false"
      :draggable="false"
      :icon="icon"
    ></GmapMarker>
    <GmapCircle
      v-if="currentPosition"
      :center="currentPosition"
      :clickable="false"
      :draggable="false"
      :radius="currentPositionAccuracy"
      :visible="true"
      :options="{
        fillColor: 'blue',
        fillOpacity: 0.2,
        strokeWeight: 0,
        cursor: 'default',
      }"
    >
    </GmapCircle>
  </span>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      icon: {
        scaledSize: { width: 20, height: 20, f: "%", b: "%" },
        anchor: { x: 10, y: 10, f: "px", b: "px" },
        url: require("@/assets/current_position_icon.png"),
      },
    };
  },
  computed: mapState(["currentPosition", "currentPositionAccuracy"]),
  methods: {},
  mounted() {},
  beforeDestroy() {
    if (this.$store.state.geoPermission) {
      navigator.geolocation.clearWatch(this.watcher);
    }
  },
};
</script>
