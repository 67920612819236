<template>
  <v-app>
    <PositionPermissionDialog />
    <Loading />
    <SnackBar />
    <CookieConsent />
    <LegendSheet />
    <v-main>
      <Map></Map>
      <router-view></router-view>
    </v-main>
    <Bottom />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import PositionPermissionDialog from "@/components/PositionPermissionDialog.vue";
import SnackBar from "@/components/SnackBar.vue";
import Loading from "@/components/Loading.vue";
import Bottom from "@/components/Bottom.vue";
import Map from "@/components/Map.vue";
import CookieConsent from "@/components/CookieConsent.vue";
import LegendSheet from "@/components/LegendSheet.vue";

export default {
  name: "Application",

  components: {
    SnackBar,
    Loading,
    Bottom,
    Map,
    PositionPermissionDialog,
    CookieConsent,
    LegendSheet,
  },
  computed: mapState(["trackingAllowed"]),
  created() {
    this.setFillHeight();
    window.addEventListener("resize", this.setFillHeight);
  },
  mounted() {
    if (this.trackingAllowed) {
      console.log("tracking start");
      this.$gtag.optIn();
    } else {
      console.log("tracking disabled");
      this.$gtag.optOut();
    }
  },
  data: () => ({
    //
  }),
  methods: {
    setFillHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
  metaInfo: {
    titleTemplate: "%s - EV充電器マップ",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "お近くの電気自動車用充電器を検索！ルートを設定した充電計画作成にご活用ください。電気自動車を便利に安心して利用する手助けに！",
      },
    ],
  },
};
</script>

<style>
.v-application--wrap {
  min-height: calc(var(--vh, 1vh) * 100) !important;
}
</style>