<template>
  <div>
    <GmapPolyline
      v-for="(step, index) in evHighwaySteps"
      :key="'highway' + String(index)"
      :path="step.path"
      :options="step.options"
    />
    <GmapPolyline
      v-for="(step, index) in evSteps"
      :key="'route' + String(index)"
      :path="step.path"
      :options="step.options"
    />
  </div>
</template>

<script>
import { EV } from "@/components/model/ev.js";
import { hubeny } from "@/lib/hubeny.js";

export default {
  computed: {
    evSteps: function () {
      // 特定のEVの設定がないとき
      if (!this.$store.state.selectedEv && this.$store.state.routeLegs) {
        const steps = genRouteSteps(this.$store.state.routeLegs);
        return steps;
      }
      // 何か車種が指定されているとき
      else if (this.$store.state.selectedEv && this.$store.state.routeLegs) {
        const ev = new EV(
          this.$store.state.selectedEv.maker,
          this.$store.state.selectedEv.model,
          this.$store.state.selectedEv.capacity,
          this.$store.state.selectedEv.energyConsumption /
            this.$store.state.rangeAdjustPram
        );
        ev.setBatteryLevel(this.$store.state.batteryLevel);
        const steps = genEvRouteSteps(this.$store.state.routeLegs, ev, this);
        return steps;
      }
      return [];
    },
    evHighwaySteps: function () {
      // 高速道路の表示をする
      if (this.$store.state.routeLegs) {
        const steps = genHighwayRouteSeps(this.$store.state.routeLegs);
        return steps;
      }
      return [];
    },
  },
};

const genRouteSteps = function (routeLegs) {
  // 特に色を変えないときの関数
  const evSteps = [];
  routeLegs.forEach(function (leg) {
    leg.steps.forEach(function (step) {
      evSteps.push({
        path: step.path,
        options: {
          clickable: false,
          strokeColor: "#0000FF",
          strokeOpacity: 1.0,
          strokeWeight: 6,
          zIndex: 3,
        },
      });
    });
  });
  return evSteps;
};

const genHighwayRouteSeps = function (routeLegs) {
  // 特に色を変えないときの関数
  const evHighwaySteps = [];
  routeLegs.forEach(function (leg) {
    leg.steps.forEach(function (step) {
      if (step.instructions.includes("有料")) {
        evHighwaySteps.push({
          path: step.path,
          options: {
            clickable: false,
            strokeColor: "#000000",
            strokeOpacity: 1.0,
            strokeWeight: 16,
            zIndex: 1,
          },
        });
        evHighwaySteps.push({
          path: step.path,
          options: {
            clickable: false,
            strokeColor: "#FFFFFF",
            strokeOpacity: 1.0,
            strokeWeight: 11,
            zIndex: 2,
          },
        });
      }
    });
  });
  return evHighwaySteps;
};

const genEvRouteSteps = function (routeLegs, ev, that) {
  // スタート時点でのバッテリー残量と残距離をcommit
  that.$store.commit("startBatteryLevel", ev.getBatteryLevel());
  that.$store.commit(
    "startAvailableRange",
    ev.getRangeAvailable(ev.getBatteryLevel())
  );
  // バッテリー残容量によって色を変える時の関数
  const evSteps = [];
  const points = [];
  // 得られたルートからポイントを抽出、一つの配列にする
  routeLegs.forEach(function (leg) {
    leg.steps.forEach(function (step) {
      step.path.forEach(function (point) {
        points.push(point);
      });
    });
  });
  const length = points.length;
  // ポイントの配列を再帰的に呼び出し、電池残量をシミュレートする
  points.reduce(function (previous, current, index) {
    // 初回
    // 配列に最初の点を入れる→次回へ渡す
    if (index == 0) {
      return [current];
    }
    // 二回目以降
    // 前回から受け取った最後の点との間で2点間の距離を計算
    const lastPoint = previous.slice(-1)[0];
    let legDistance = hubeny(
      lastPoint.lat(),
      lastPoint.lng(),
      current.lat(),
      current.lng()
    );
    // 走行によるバッテリー消費をシミュレート
    if (!isNaN(legDistance)) {
      ev.run(legDistance);
    }
    // 配列に現在の点を追加
    previous.push(current);

    // バッテリー残量に従って色を変更
    if (previous.length == 100 || index == length - 1) {
      evSteps.push({
        path: previous,
        options: {
          clickable: false,
          strokeColor: getColor(ev.getBatteryLevel()),
          strokeOpacity: 1.0,
          strokeWeight: 6,
          zIndex: 3,
        },
      });
      return [current];
    } else {
      return previous;
    }
  }, []);
  that.$store.commit("endBatteryLevel", ev.getBatteryLevel());
  that.$store.commit(
    "endAvailableRange",
    ev.getRangeAvailable(ev.getBatteryLevel())
  );
  return evSteps;
};

const getColor = function (batteryLevel) {
  const color = [getR(batteryLevel), getG(batteryLevel), getB(batteryLevel)];
  return rgb2hex(color);
};

const getR = function (value) {
  if (value <= 0) {
    return 90;
  } else if (value < 25) {
    return 255;
  } else if (value >= 25 && value < 50) {
    return parseInt(-10.2 * value + 510);
  } else {
    return 0;
  }
};

const getG = function (value) {
  if (value <= 0) {
    return 90;
  } else if (value < 25) {
    return parseInt(10.2 * value);
  } else if (value >= 25 && value < 75) {
    return 255;
  } else {
    return parseInt(-10.2 * value + 1020);
  }
};

const getB = function (value) {
  if (value <= 0) {
    return 90;
  } else if (value < 50) {
    return 0;
  } else if (value >= 50 && value < 75) {
    return parseInt(10.2 * value - 510);
  } else {
    return 255;
  }
};

const rgb2hex = function (rgb) {
  return (
    "#" +
    rgb
      .map(function (value) {
        return ("0" + value.toString(16)).slice(-2);
      })
      .join("")
  );
};
</script>
