<template>
  <GmapMap
    id="map"
    ref="map"
    :center="center"
    :zoom="zoom"
    map-type-id="roadmap"
    :options="options"
    @center_changed="setSearchCenter"
  >
    <ChargerCluster />
    <CurrentPosition />
    <StartRouteMarker v-if="start" :place="start" />
    <DestinationRouteMarker v-if="destination" :place="destination" />
    <RoutePolyline />
  </GmapMap>
</template>

<script>
import { mapState } from "vuex";
import { EventBus } from "@/event.js";
import CurrentPosition from "@/components/CurrentPosition.vue";
import StartRouteMarker from "@/components/StartRouteMarker.vue";
import DestinationRouteMarker from "@/components/DestinationRouteMarker.vue";
import RoutePolyline from "@/components/RoutePolyline.vue";
import ChargerCluster from "@/components/ChargerCluster.vue";
import { gmapApi } from "vue2-google-maps";
import axios from "axios";

export default {
  name: "Map",
  components: {
    CurrentPosition,
    StartRouteMarker,
    DestinationRouteMarker,
    RoutePolyline,
    ChargerCluster,
  },
  data() {
    return {
      center: { lat: 35.681382, lng: 139.76608399999998 },
      zoom: 15,
      options: {
        gestureHandling: "greedy",
        zoomControl: false,
        clickableIcons: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: [],
        keyboardShortcuts: false,
      },
      invokeDelayTimer: null,
      sessionTimer: null,
      directionsService: null,
      directionsDisplay: null,
      boundingSearchHandler: null,
    };
  },
  methods: {
    searchCenter() {
      const that = this;
      this.$gtag.event("search", {
        event_category: "centerSearch",
      });
      // API call
      const method = "post";
      const url = `${process.env.VUE_APP_SEARCH_API_ENDPOINT}/search/center`;
      const data = {
        size: 50,
        radius: 10000,
        center: { lat: this.center.lat, lon: this.center.lng },
        normalChargerFlag: this.$store.state.hasNormal,
        rapidChargerFlag: this.$store.state.hasRapid,
        alldayFlag: this.$store.state.allDay,
        dealerFlag: this.$store.state.dealer,
      };
      axios({
        method,
        url,
        data,
      }).then((response, err) => {
        if (err) {
          EventBus.$commit("error", "検索できませんでした");
        } else {
          if (that.$route.name == "Around") {
            that.$store.commit("chargers", response.data);
          }
        }
      });
    },
    fitBound() {
      if (this.google) {
        const bounds = new this.google.maps.LatLngBounds();
        if (this.$store.state.start != null) {
          bounds.extend(this.start.position);
        }
        if (this.$store.state.destination != null) {
          bounds.extend(this.destination.position);
        }
        if (this.$store.state.chargers) {
          this.$store.state.chargers.forEach((charger) => {
            bounds.extend({
              lat: charger.location.lat,
              lng: charger.location.lon,
            });
          });
        }
        if (!bounds.isEmpty()) {
          this.$refs.map.$mapObject.fitBounds(bounds, {
            top: 180,
            bottom: 100,
          });
          // zoomが6以上だったら6にする
          const zoom = this.$refs.map.$mapObject.getZoom();
          this.$refs.map.$mapObject.setZoom(zoom > 16 ? 16 : zoom);
        }
      } else {
        setTimeout(this.fitBound, 100);
      }
    },
    setSearchCenter(mapCenter) {
      if (mapCenter) {
        const that = this;
        clearTimeout(this.boundingSearchHandler);
        if (that.$route.name == "Around") {
          this.boundingSearchHandler = setTimeout(() => {
            const center = {
              lat: mapCenter.lat(),
              lng: mapCenter.lng(),
            };
            that.center = center;
            that.searchCenter();
          }, 800);
        }
      }
    },
  },
  computed: { ...mapState(["start", "destination"]), google: gmapApi },
  mounted() {
    EventBus.$on("bounding", () => {
      this.fitBound();
    });
    EventBus.$on("centering", (position) => {
      if (
        this.$route.name == "Favorite" &&
        this.$vuetify.breakpoint.smAndDown
      ) {
        console.log();
        this.$refs.map &&
          this.$refs.map.$mapPromise.then(function (map) {
            map.panTo(position);
            map.panBy(-128, -150);
          });
      } else {
        this.$refs.map &&
          this.$refs.map.$mapPromise.then(function (map) {
            map.panTo(position);
            map.panBy(0, -100);
          });
      }
    });
    EventBus.$on("toHome", () => {
      this.searchCenter();
    });
    EventBus.$on("refine", () => {
      clearTimeout(this.boundingSearchHandler);
      this.$route.name == "Around" && this.searchCenter();
    });
    if (!this.$store.state.geoPermission) {
      this.searchCenter();
    }
  },
};
</script>

<style scoped>
#map-div,
#map {
  height: 100%;
  z-index: 0;
}
</style>