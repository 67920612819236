<template>
  <v-bottom-navigation
    app
    width="100%"
    background-color="primary"
    color="white"
    shift
  >
    <v-btn :to="{ name: 'Around' }" :ripple="$route.name !== 'Around'">
      <span>周辺</span>
      <v-icon>mdi-map-marker-radius-outline</v-icon>
    </v-btn>

    <v-btn :to="{ name: 'Search' }" :ripple="$route.name !== 'Search'">
      <span>検索</span>
      <v-icon>mdi-map-search</v-icon>
    </v-btn>

    <v-btn :to="{ name: 'Favorite' }" :ripple="$route.name !== 'Favorite'">
      <span>お気に入り</span>
      <v-icon>favorite</v-icon>
    </v-btn>

    <v-btn :to="{ name: 'Guide' }" :ripple="$route.name !== 'Guide'">
      <span>診断</span>
      <v-icon>psychology</v-icon>
    </v-btn>

    <v-btn :to="{ name: 'Info' }" :ripple="$route.name !== 'Info'">
      <span>インフォ</span>
      <v-icon>info</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
@media screen and (max-width: 480px) {
  .v-bottom-navigation .v-btn {
    min-width: 63px !important;
  }
}
</style>
