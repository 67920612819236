<template>
  <div>
    <v-bottom-sheet max-width="500px" v-model="sheet">
      <v-sheet>
        <v-list-item class="legend-item px-2">
          <v-list-item-content class="body-2 mx-3">
            充電器の絞り込みツール
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="legend-item px-2">
          <v-img
            contain
            height="140px"
            class="mb-4"
            src="/img/panel_description.png"
          ></v-img>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-if="$route.name == 'Search'" class="legend-item px-2">
          <v-list-item-content class="body-2 mx-3">
            ルート表示時のバッテリー残量表現
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$route.name == 'Search'">
          <div class="gradient-sample">
            <div class="gradient-sample-text body-2" style="left: 10%">
              0%以下
            </div>
            <div class="gradient-sample-text body-2" style="left: 26%">0%</div>
            <div class="gradient-sample-text body-2" style="right: 10%">
              100%
            </div>
            <div class="gradient-sample-bar"></div>
          </div>
        </v-list-item>
        <v-divider v-if="$route.name == 'Search'"></v-divider>
        <v-list-item class="legend-item px-2">
          <v-list-item-avatar>
            <v-img
              contain
              height="35px"
              src="/img/charger_icon/charge_icon_set_01.png"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="body-2 py-1">
            急速・普通充電器両方あり
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="legend-item px-2">
          <v-list-item-avatar>
            <v-img
              contain
              height="35px"
              src="/img/charger_icon/charge_icon_fast_01.png"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="body-2 py-1">
            急速充電器あり
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="legend-item px-2">
          <v-list-item-avatar>
            <v-img
              contain
              height="35px"
              src="/img/charger_icon/charge_icon_normal_01.png"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="body-2 py-1">
            普通充電器あり
          </v-list-item-content>
        </v-list-item>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { EventBus } from "@/event.js";

export default {
  data() {
    return {
      sheet: false,
    };
  },
  mounted() {
    EventBus.$on("legend", (sheet) => {
      this.sheet = sheet;
    });
  },
};
</script>

<style scoped>
.gradient-sample {
  height: 30px;
  width: 100%;
}
.gradient-sample-text {
  position: absolute;
}
.gradient-sample-bar {
  background: linear-gradient(
    90deg,
    #5a5a5a 0%,
    #5a5a5a 20%,
    #ff0000 20%,
    #ffff00 40%,
    #00ff00 60%,
    #00ffff 80%,
    #0000ff 100%
  );
  width: 80%;
  height: 10px;
  margin: auto;
  margin-top: 20px;
}
</style>