<template>
  <GmapCluster :minimumClusterSize="4">
    <ChargerMarker
      v-for="(chargerInfo, index) in chargers"
      :key="chargerInfo.id"
      :chargerInfo="chargerInfo"
      :index="index"
    />
  </GmapCluster>
</template>

<script>
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import ChargerMarker from "@/components/ChargerMarker.vue";
import { mapState } from "vuex";

export default {
  components: { GmapCluster, ChargerMarker },
  computed: mapState(["chargers"])
};
</script>
