export class EV {
    // 電力消費率はWLTCモードを入力のこと
    constructor(maker, model, capacity, energyConsumption) {
      this.maker = maker; // メーカー名
      this.model = model; // 車種名
      this.capacity = capacity; // 電池容量 in wh
      this.remainingCapacity = capacity; // 残電池容量 in wh
      this.energyConsumption = energyConsumption; // 電気消費量 in km/wh
    }
  
    run(distance) {
      this.remainingCapacity =
        this.remainingCapacity - (distance / 1000) * this.energyConsumption;
    }
  
    getModelInfo() {
      return {
        maker: this.maker,
        model: this.model,
        capacity: this.capacity / 1000,
        energyConsumption: this.energyConsumption
      };
    }
  
    getBatteryLevel() {
      return (this.remainingCapacity / this.capacity) * 100;
    }
  
    getRemainingCapacity() {
      return this.remainingCapacity;
    }
  
    getRangeAvailable(batteryLevel) {
      let tmpRemainingCapacity = (this.capacity * batteryLevel) / 100;
      return tmpRemainingCapacity / this.energyConsumption;
    }
  
    setBatteryLevel(targetBatteryLevel) {
      if (targetBatteryLevel > 100) {
        targetBatteryLevel = 100;
      }
      this.remainingCapacity = this.capacity * (targetBatteryLevel / 100);
      return this.getBatteryLevel();
    }
  }