<template>
  <Loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></Loading>
</template>

<script>
import { EventBus } from "@/event.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {Loading},
  data() {
    return {
      loading: false
    };
  },
  mounted() {
    EventBus.$on("loading", loading => {
      this.loading = loading;
    });
  }
};
</script>

