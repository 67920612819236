<template>
  <a :href="url" target="_blank" rel="noopener noreferrer" @click="linkClick">
    {{ linkText }}
  </a>
</template>

<script>
export default {
  props: ["url", "linkText", "linkEventLabel"],
  methods: {
    linkClick() {
      this.$gtag.event("click", {
        event_category: "linkClick",
        event_label: this.linkEventLabel ? this.linkEventLabel : "null",
      });
    },
  },
};
</script>

