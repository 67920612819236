<template>
  <GmapMarker
    :position="place.position"
    :clickable="true"
    :draggable="false"
    :title="place.title"
    @click="openWindow"
    :label="{
      text: 'G',
      color: '#303030',
      fontFamily: 'sans-serif',
      fontWeight: 'bold',
      fontSize: '16px',
    }"
  >
    <GmapInfoWindow
      v-if="selectedEv && endBatteryLevel && endAvailableRange"
      :opened="opened"
      @closeclick="opened = false"
    >
      <template v-if="endBatteryLevel <= 20">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <div class="center" style="color: red; font-weight: bold">
                <v-icon color="red" small>mdi-battery-charging-20</v-icon>
                {{ endBatteryLevel.toFixed() }} %
              </div>
              <div class="center" style="color: red; font-weight: bold">
                <v-icon color="red" small>mdi-car</v-icon>
                {{ endAvailableRange.toFixed(1) }} km
              </div>
            </div>
          </template>
          <div>
            <div>到着地点での予想充電池残量</div>
            <div>到着地点での予想走行可能距離</div>
          </div>
        </v-tooltip>
      </template>
      <template v-else>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <div class="center">
                <v-icon color="#101010" small>mdi-battery-charging-80</v-icon>
                {{ endBatteryLevel.toFixed() }} %
              </div>
              <div class="center">
                <v-icon color="#101010" small>mdi-car</v-icon>
                {{ endAvailableRange.toFixed(1) }} km
              </div>
            </div>
          </template>
          <div>
            <div>到着地点での予想充電池残量</div>
            <div>到着地点での予想走行可能距離</div>
          </div>
        </v-tooltip>
      </template>
    </GmapInfoWindow>
  </GmapMarker>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      opened: true,
    };
  },
  props: ["place"],
  methods: {
    openWindow() {
      this.opened = true;
    },
  },
  computed: mapState(["endBatteryLevel", "endAvailableRange", "selectedEv"]),
};
</script>

<style scoped>
.center {
  text-align: center;
  border-bottom: 1px solid #101010;
  margin: 5px;
}
</style>