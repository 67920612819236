<template>
  <v-alert
    dark
    color="secondary"
    class="cookieconsent"
    :value="!cookieConsented"
    elevation="2"
  >
    このサイトでは、サイト・サービス・広告を改善するためにCookieを利用します。
    <v-btn
      dark
      text
      class="text-decoration-underline"
      :to="{ name: 'PrivacyPolicy' }"
      >プライバシーポリシーを確認</v-btn
    >
    <v-btn
      color="white"
      class="grey--text text--darken-4 font-weight-bold"
      @click="consentTracking"
      >確認した</v-btn
    >
  </v-alert>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "cookieConsent",
  computed: mapState(["cookieConsented"]),
  methods: {
    // declineTracking() {
    //   this.$gtag.optOut();
    //   this.$store.commit("trackingAllowed", false);
    //   this.$store.commit("cookieConsented", true);
    // },
    consentTracking() {
      this.$gtag.optIn();
      this.$store.commit("trackingAllowed", true);
      this.$store.commit("cookieConsented", true);
      if (this.$route.name == "PrivacyPolicy") this.$router.go(-1);
    },
  },
};
</script>


<style scoped>
.cookieconsent {
  position: fixed;
  z-index: 1000;
  bottom: 0px;
  width: 95%;
  margin: 0px 2.5%;
  text-align: center;
}
</style>
